<template>

  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-8">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ is_editing ? $t('creator.editSellReq') : $t('creator.addSellReq') }}</h1>
        <p>{{ $t('creator.getInfo') }}</p>
        <div>
          <b-form-group :label="$t('creator.questionCall')" label-for="input-description" class="form-group-layout">
            <b-form-textarea :state="stateDescription(request)" aria-describedby="input-live-help invalid-feedback-description" id="input-description" v-model="request.question" rows="3"></b-form-textarea>
            <b-form-invalid-feedback id="invalid-feedback-title">
                <span v-if="request.question.length > 1000">{{ $t('creator.1000charactersRequest') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="bg-white p-2" v-if="request.type_question.value === 'radio' || request.type_question.value === 'checkbox'">
            <b-form-group :label="`${$t('creator.optionCall')} ${index_options + 1}`" label-for="input-description" v-for="(i, index_options) in request.options" :key="index_options" class="form-group-layout">
                <div class="d-flex">
                <b-form-input :state="stateEmpty(request.options[index_options])" id="input-description" v-model="request.options[index_options]" rows="3" @keyup.enter="request.options.length > 0 ? save(): ''"></b-form-input>
                <b-button 
                    v-if="index_options !== 0 && index_options !== 1" 
                    class="p-0 p-05 ml-1" 
                    variant="flat-secondary"
                    @click="deleteOption(index_options)"
                >
                <feather-icon icon="TrashIcon" size="18" ></feather-icon></b-button>
                </div>
            </b-form-group>
            
            <b-button variant="primary" @click="addOption()">{{ $t('creator.addOption') }}</b-button>
            <div class="invalid-feedback-deals mt-1" v-if="request.options.length < 2">
                {{$t('creator.at_least_2_option')}}
            </div>
            </div>
            <b-form-group :label="$t('creator.responseType')" label-for="input-delivery" class="mt-2 form-group-layout">
              <component :is="vue_select" label="text" :options="$t('creator.request_type')" id="input-type-question" v-model="request.type_question">
                <template #option="{value, text, long_text}">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <b-icon :icon="getIconsRequest(value)" class="mr-05"></b-icon>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="d-block">{{text}}</span>
                      <span class="avenir-medium white-space-pre-wrap">{{long_text}}</span>
                    </div>
                  </div>
                </template>
              </component>
            </b-form-group>
            <div class="d-flex justify-content-between mt-2">
            <span class="label-checkbox">{{ $t('creator.questionRequired') }}</span>
            <b-form-checkbox v-model="request.required_question" switch>
            </b-form-checkbox>
          </div>
        </div>
        <b-col class="col-12 ">
          <b-button v-if="is_editing" class="my-2 mr-1" variant="danger" @click.once="deleteRequest()">{{ $t('creator.delete') }}</b-button>
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-3" :disabled="!isPassingValidations()" @click.once="save()">{{ $t('creator.saveSeePreview') }}</b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BFormInvalidFeedback,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';

export default {
  name: 'requestsComponent',
  components: {
    BRow,   
    BCol,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      steps: null,
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      text_warning: '',
      proposal: {},
      request: Object.keys(this.request_selected).length > 0 ? {...this.request_selected} : {
        question: this.$t('creator.anyQuestion'),
        type_question: this.$t('creator.request_type')[0],
        required_question: false,
        options: []
      },
      vue_select: null
    }
  },
  props: {
    request_selected: {
      type: Object,
      default: () => {
        return {}
      }
    },
    is_editing: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    stateDescription(request) {
      if (request.question) return request.question.length > 0 && request.question.length < 1000;
      else return false
    },
    stateEmpty(option) {
      if (option) return option.length > 0;
      else return false
    },

    getData() {
      this.proposal = this.$route.params.proposal;
      this.request.options = this.request.options.map((option) => option.title);
      if (Object.keys(this.request_selected).length > 0) this.request.type_question = this.$t('creator.request_type').find((i) => i.value === this.request.type_question)
    },
    save() {
      if (this.isPassingValidations()) {
        const request_to_ws = Object.assign({}, this.request)
        request_to_ws.type_question = this.request.type_question.value;
        if (this.request.uuid) {
          proposals_services.updateElement(this.proposal.uuid, request_to_ws.uuid, request_to_ws, 'request').then((response) => {
            this.$emit('update_proposal', response)
          });
        } else {
          proposals_services.addOptionOrRequest(this.proposal.uuid, {requests: [request_to_ws]}, 'request').then((response) => {
            this.$emit('update_proposal', response)
          });
        }        
      }
    },
    deleteRequest() {
      proposals_services.deleteElement(this.proposal.uuid, this.request.uuid, 'request').then((response) => {
        this.$emit('update_proposal', response)
      });
    },
    addOption() {
      this.request.options.push(`${this.$t('creator.optionCall')} ${this.request.options.length + 1}`);
    },
    deleteOption(index_option) {
      this.request.options = this.request.options.filter((option, index) => index !== index_option)
    },
    isPassingValidations() {
      const is_passing_all = [];
      this.request.options.forEach((option) => {
        is_passing_all.push(this.stateEmpty(option))
      });
      is_passing_all.push(this.stateDescription(this.request))
      if (this.request.type_question.value === 'radio' || this.request.type_question.value === 'checkbox') is_passing_all.push(this.request.options.length >= 2);
      return is_passing_all.every((item) => item === true);
    },
    getIconsRequest(type) {
      if (type === 'free_text') return 'textarea-t';
      if (type === 'radio') return 'ui-radios';
      if (type === 'checkbox') return 'ui-checks';
      if (type === 'media') return 'file-earmark-arrow-up';
    },
  },
}
</script>
<style>
.form-group-layout > label {
  text-align: left;
}
.label-checkbox {
  color: #5e5873;
  font-size: 0.857rem;
}
.p-05 {
  padding: 0.5em !important;
}
#input-type-question {
  background-color: white;
}
#input-type-question .vs__clear {
  display: none;
}
</style>
<style scoped>
.white-space-pre-wrap {
  white-space: pre-wrap;
}
.invalid-feedback-deals {
  font-size: 0.857rem !important;
  color: #496daa !important;
}
</style>